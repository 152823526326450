import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ApiSidebar from "../../components/api-sidebar"
import ApiDocItem from "../../components/api-doc-item"
import ApiDocFooter from "../../components/api-doc-footer"
import ApiParameterTable from "../../components/api-parameter-table"
import ApiKeyField from "../../components/api-key-field"
import CodeBlock from "../../components/code-block"
import CodeTransformer from "../../components/code-transformer"

import apiSettings from "../../utils/api-settings"
import Transformer from "../../utils/code-transformer/transformer"

const endpoint = "play/text"

const transformer = new Transformer({
  method: "post",
  options: {
    endpoint: endpoint,
    apiBaseUrl: apiSettings.apiBaseUrl,
    apiKey: apiSettings.apiKey,
  },
})

const ApiPlayTextPage = () => (
  <Layout sidebar={ApiSidebar()} title="Play Text API">
    <SEO title="Play Text API" />
    <p>
      Use the <i>Play Text API</i> to play custom text-to-speech (TTS)
      notifications with optional effects on your Sonos speakers. Just replace{" "}
      <code>{apiSettings.apiKeyPlaceholder}</code> with your own key.
    </p>

    <p>
      To use the <i>Play Text API</i> you need credits. Depending on the
      selected voice 2 or 3 credits are necessary to play a single text
      notfication. <Link to="/pricing/">Learn more about credits here.</Link>
    </p>

    <ApiKeyField />

    <ApiDocItem>
      <h3>Endpoint</h3>
      <CodeBlock
        platform="bash"
        code={{
          bash: {
            code: `POST  ${apiSettings.apiBaseUrl}/${endpoint}`,
            codeToCopy: `${apiSettings.apiBaseUrl}/${endpoint}`,
          },
        }}
        showPlatformPicker={false}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Basic example</h3>
      <p>
        This will play the given text message with your default settings on your
        Sonos speakers. <code>text</code> is the only required parameter.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            text: "Hi! My name is Quak and I will say whatever you want.",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Set your favorite voice</h3>
      <p>
        Set the <code>voice</code> parameter like this and override your default
        voice.{" "}
        <Link to="/api/list-voices/">Get a list of all available voices.</Link>{" "}
        You can use any voice which is available via Google and AWS (Amazon).
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            text:
              "Just trying out Quak, the new super cool web service to play audio notifications on my Sonos speakers.",
            voice: "en-us-wavenet-e",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Use intro and outro sounds</h3>
      <p>
        This will play the defined intro and outro sounds. You can set{" "}
        <code>intro</code> and/or <code>outro</code> to a sound from{" "}
        <Link to="/api/list-sounds/">this list </Link>. You can pass{" "}
        <code>null</code> if you don't want to use a intro/outro sound to
        override your global default.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            text: "Sounds like a really cool duck. What do you think?",
            voice: "en-us-wavenet-a",
            intro: "quakquak",
            outro: "walkie-talkie",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Set volume & speakers</h3>
      <p>
        Set <code>targets</code> (speakers, groups, ...) and <code>volume</code>{" "}
        to override your global defaults.{" "}
        <Link to="/api/list-targets/">
          Get a list of all available targets.
        </Link>
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            text: "Hey dude. Are you ok? I mean... why do you talk with ducks?",
            targets: "livingroom",
            volume: 10,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Play on multiple targets</h3>
      <p>
        To play on multiple targets at the same time, set the{" "}
        <code>targets</code> parameters like this.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            text: "Hi. This voice is coming out of multiple Sonos speakers.",
            targets: ["livingroom", "bath"],
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Play with audio effects</h3>
      <p>
        Apply <code>speed</code> and <code>reverb</code> audio effects to the
        message itself. Learn more about effects in the{" "}
        <i>Request Parameters</i> section below.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            text: "I am talking really fast now with some fancy reverb effect.",
            speed: 30,
            reverb: 50,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Preview mode</h3>
      <p>
        If you just want to preview the playback, you can pass{" "}
        <code>preview</code> and nothing will be played on your Sonos speakers.
        But the JSON response will include an URL to the audio file.{" "}
        <b>
          If played for the first time there is no cached version on the server.
          Hence it will cost credits for synthesizing voice to text (1 or 2
          credits).
        </b>
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            text: "Testing the preview mode. Check check. 1... 2... 3...",
            preview: true,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Play only on specific days and times</h3>
      <p>
        Use the <code>days</code> and <code>hours</code> parameters to specify
        when to play the text notification. If week day and time (
        <a href="https://app.quak.sh/settings" target="_blank">
          check your time zone
        </a>
        ) does not match nothing will be played. Notifications will not be
        queued.
      </p>
      <p>
        Let's say you don't want to play notifications in the night because...
        well... you are sleeping. It would be a waste of energy and Quak credits
        if the text message would be played 24/7.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            text: "You will not hear this message after 6 pm.",
            days: "mo-fr",
            hours: "8-18",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Request Parameters</h3>
      <p>This is a full list of the request parameters you can use:</p>
      <ApiParameterTable type="TEXT" />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Example Response</h3>
      <p>
        If the request was successful you will receive a response like this one.
        The <code>uuid</code> is the unique identifier you can use to get
        updates regarding playback status.
      </p>
      <p>
        {" "}
        Directly after submitting <code>status</code> is{" "}
        <code className="value">PENDING</code>. Only if you use{" "}
        <code>preview</code> the <code>status</code> is{" "}
        <code className="value">DONE</code>.{" "}
        <Link to="/api/response-codes/">Learn more about response codes.</Link>
      </p>
      <CodeBlock
        platform="json"
        code={{
          json: {
            code: `{
  "uuid": "a25ec90a-13dc-4155-96ff-3d2046f1c2bf",
  "createdAt": "2019-05-23T13:52:36.399Z",
  "type": "TEXT",
  "status": "PENDING",
  "audioUrl": "https://quakquak.s3.eu-central-1.amazonaws.com/out/1eac/40c8/1eac40c8-e4b0-401b-ae7c-f7a9a756b32c.mp3?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIV5WXHY5MSMXPN7Q%2F20190523%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20190523T135236Z&X-Amz-Expires=180&X-Amz-Signature=7c52bfcb28faf057386c2b5d985b9e7a42a79c4234f6b3979f147c2c34a5c61c&X-Amz-SignedHeaders=host",
  "audioId": "1eac40c8-e4b0-401b-ae7c-f7a9a756b32c",
  "audioUrlExpiresAt": "2019-05-23T13:55:36.811Z",
  "length": 4.52,
  "credits": 2,
  "fromCache": false,
  "players": [
    {
      "uuid": "f89fcd4d-05d1-4231-a954-ca5f5cead5a1",
      "slug": "livingroom",
      "name": "Livingroom",
      "provider": "SONOS",
      "status": "PENDING"
    }
  ],
  "params": {
    "voice": "en-us-joanna",
    "intro": "quakquak",
    "outro": "walkie-talkie",
    "delay": 200,
    "volume": 30,
    "targets": [
      "livingroom"
    ],
    "speed": 0,
    "tempo": 0,
    "pitch": 0,
    "reverb": 0,
    "reverse": false,
    "preview": false,
    "priority": false,
    "skipCache": false,
    "text": "Hi! My name is Quak and I will say whatever you want."
  }
}
`,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocFooter active="/api/play-text/" />
  </Layout>
)

export default ApiPlayTextPage
